$primary: #335eee;
$primaryDark: #2d53d1;
$primaryLight: #436bef;

$white: #fff;
$black: #000;
$dark: #12161d;
$sMono: #262b34;
$lessDark: #262b34;
$gray: #6f7278;
$lightGray: #acafb5;
$lighterGray: #ced1d7;
$lightestGray: #eff1f5;
$offWhite: #f9fafc;
$border: rgba(#acafb5, 0.3);
$focusBorder: rgba(#acafb5, 0.4);
$inactiveBorder: #d0d5dd;

$error: #f04533;
$warning: #ffcf52;
$success: #22c799;

$blue: #0062ff;
$blue2: #553afe;
$blue3: #01c0f6;
$orange: #ff974a;
$red: #fc5a5a;
$maroon: #912018;
$green: #82c43c;
$green2: #3bcf88;
$purple: #a461d8;
$pink: #fe7e9f;
$yellow: #ffc542;

$heading-weight: bold;
$heading-l: 42px;
$heading-m: 36px;
$heading-s: 32px;
$heading-xs: 26px;
