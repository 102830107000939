#simple-job-seach-page {
  max-width: 75ch;
  margin: auto;
  height: 100%;
  display: grid;
  place-items: center;

  .wrapper {
    .header {
      margin-bottom: 24px;
      font-size: 2rem;
    }

    .job-select {
      margin-bottom: 18px;
      width: 100%;

      & > div {
        width: 100%;

        .fancy-select-actual-select > div {
          width: 100%;
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1rem;

      & > * {
        width: 100%;
      }
      .anchor-button {
        justify-content: center;
      }
    }
  }
}
