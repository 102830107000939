.card {
  width: 100%;
  margin-bottom: 24px;
}

.simple-tables {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .simple-table {
    background-color: white;
    height: max-content;
    flex: 1;
    border-radius: 0.5em;
    box-shadow: 0px -2px 6px -1px rgb(0 0 0 / 2%),
      0px 4px 10px -2px rgb(0 0 0 / 5%);

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 12px;
      margin: 0;
      border-bottom: 1px solid hsla(220, 6%, 69%, 0.3); //var.$border-line
    }

    table {
      width: 100%;
      border-spacing: 0;

      tr {
        outline: none;
      }
      td {
        padding: 12px;
        width: 50%;
        border-bottom: 1px solid hsla(220, 6%, 69%, 0.3); //var.$border-line
      }

      td[data-label] {
        background-color: #f9fafc; //var.$off-white
      }
      td[data-value] {
        text-align: right;
        border-left: 1px solid hsla(220, 6%, 69%, 0.3); //var.$border-line
      }
    }

    .footer {
      padding: 12px;
      display: flex;
      gap: 1rem;
      flex-direction: row-reverse;
    }
  }
}

.job-overview {
  width: 100%;

  .header {
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
    }
  }

  .summary-grid {
    display: grid;
    gap: 1rem;

    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;

    .map {
      object-fit: cover;
      min-width: 350px;
      width: 100%;
      height: 300px;
      border-radius: 0.5em;
      overflow: hidden;
    }

    .map-fallback {
      display: grid;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 1400px) {
      grid-template-columns: 1fr 350px;

      .map {
        width: 250px;
        height: 100%;
      }
    }
  }

  .detail-sections > section {
    border-bottom: 1px solid hsla(220, 6%, 69%, 0.3); //var.$border-line
    padding: 16px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;

    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }

    p.summary-item-label {
      color: #6F7278; //var.$light-gray
      margin: 0;
    }
    ul.summary-item-details {
      color: #12161d; //var.$dark
      list-style: none;
      padding-left: 0;
      margin: 6px 0;
      li {
        margin: 0 0 6px;
        .null-value {
          color: #ced1d7;
        }
      }
    }
  }

  .detail-sections > section:last-child {
    border-bottom: none;
  }

  .map {
    border-radius: 0.5em;
    overflow: hidden;
    min-width: 350px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .summary-grid {
    grid-template-columns: 1fr 350px;
  }
  .simple-tables {
    flex-direction: row;
  }
}
