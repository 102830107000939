@import "src/theme.scss";

.flag {
  .high,
  .low {
    color: white;
  }

  .high {
    background-color: $error;
  }
  .moderate {
    background-color: $warning; 
  }
  .low {
    background-color: transparent;
  }
  .closed {
    background-color: $lightGray;
    color: white;
  }

  .large {
    font-size: 1rem;
    padding: 0.2em 0.8em;
    border-radius: 1em;
  }
  .default {
    font-size: 1rem;
    padding: 0.2em 1em;
    border-radius: 1em;
  }
  .small {
    font-size: 0.85rem;
    padding: 0.3em 1em;
    border-radius: 1em;
  }
}
