.prompt {
  max-width: 75ch;
  margin: auto;
  display: grid;
  height: 100%;
  place-items: center;
  justify-content: center;
  text-align: center;

  .wrapper {
    .header {
      margin-bottom: 24px;
      font-size: 2rem;
    }
  }
}
