.page-layout {
  height: 100%;

  .outlet {
    padding-bottom: 24px;
    // height: 100%;
  }

  .search-bar {
    display: flex;
    gap: 0.8rem;
    margin-bottom: 24px;

    & > .select-input,
    .text-input {
      width: 250px;
    }
  }

  .table-header {
    margin-bottom: 30px;
  }

  .page-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > * {
      margin: 0;
    }

    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
    .share-button {
      margin-left: 0;
    }
    .share-button:hover {
      cursor: copy;
    }

    .risk-monitor-link {
      color: white;

      span {
        font-weight: bold;
        border-bottom: none;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}
