#app-loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
}

// .login-wrapper {
//   width: 200px;

//   & > div:not(.image-wrapper) {
//     margin-bottom: 5px;
//   }
// }

// .image-wrapper {
//   text-align: center;
//   margin-bottom: 15px;
// }

// .login-input {
//   width: 100%;
// }

// .login-button {
//   width: 100%;
// }
