@import "../../theme.scss";

.default-wrapper {
  color: $lightGray;
  // height: 100%;
  
  &.large {
    height: 75vh;
  }
}
